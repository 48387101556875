<template>
  <div :style="slidePosition" class=" z-2 w-100 h-100">
    <div
      class="position-absolute bg-dark-blue border-warning text-warning square4"
      style="top:50%; left:5%;width:60%;padding:2.5% 2.5%;transform: translateY(-50%); border: 0.5vh solid;"
    >
      <h6 class="text-white font-vw-3-15 font-w-600 fade4 " style="opacity:0;">
        1965
      </h6>
      <h6
        class="text-white font-vw-3-15 font-w-600 fade4 my-0"
        style="opacity:0;"
      >
        APPROVED TO ACCELERATE
      </h6>
      <p class="mt-3 text-white font-vw-1-5 text4 mb-0" style="opacity:0;">
        After three years of planning, on April 8, 1968, an unassuming envelope
        arrived at the University of British Columbia. Inside: TRIUMF’s first
        cheque from the federal government. Eight days later, the Honourable
        Jean-Luc Pepin, Minister of Energy, Mines and Resources, announced
        federal government approval. The new world-class physics lab received
        $19M over the next six years and two University of British Columbia
        physics professors were officially named to lead it: John Warren,
        Director; and Erich Vogt, Associate Director.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },

  mounted() {
    //this.startLoop()
  },
  methods: {
    tl4(percentage) {
      let animation4 = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 1000
      })

      animation4
        .add({
          targets: ".square4",
          opacity: [0, 1],
          duration: 600,
          delay: 5000,
          easing: "easeInQuart"
        })
        .add({
          targets: ".fade4",

          translateY: ["1vh", "0vh"],
          opacity: [0, 1],
          duration: 1000,
          easing: "easeInOutCubic",
          delay: (el, i) => 22000 + 1200 * i
        })
        .add({
          targets: ".text4",

          opacity: [0, 1],

          translateY: ["35%", "0%"],
          duration: 1200,
          delay: 2000,
          easing: "easeInOutCubic"
        })

      animation4.seek(
        percentage <= 0.5
          ? animation4.duration * (2 * percentage)
          : animation4.duration * (1 - percentage)
      )
    }
  },
  watch: {
    innerPosition() {
      this.tl4(this.innerPosition)
    }
  }
}
</script>

<style></style>
